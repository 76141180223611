import { css } from "goober";

export const TableContainer = () => css`
  .ability-meta {
    display: flex;
    align-items: center;
    gap: var(--sp-2);
  }
  .ability-name {
    width: 11em;
    white-space: normal;
    line-height: 1.25;
  }
`;
